import { _GetPageType } from '@definations/slug.type';
import Head from 'next/head';
import React from 'react';
import { _globalStore } from 'store.global';

let mediaBaseUrl = _globalStore.blobUrl; // for server side

interface _props {
  title: string;
  keywords: string;
  description: string;
  metadata?: _GetPageType;
}

const SeoHead: React.FC<_props> = (props) => {
  const {metadata} = props
  return (
    <Head>
      <title>{props.title}</title>
      <meta name='description' content={props.description} key='desc' />
      <meta name='keywords' content={props.keywords} />

      {metadata?.openGraphImagePath && (
        <meta
          name='og:image'
          content={mediaBaseUrl + metadata.openGraphImagePath}
        />
      )}
      {metadata?.openGraphTitle && (
        <meta name='og:title' content={metadata.openGraphTitle} />
      )}
      {metadata?.openGraphDescription && (
        <meta name='og:description' content={metadata.openGraphDescription} />
      )}

      {metadata?.twitterImagePath && (
        <meta
          name='twitter:image'
          content={mediaBaseUrl + metadata.twitterImagePath}
        />
      )}
      {metadata?.twitterOpenGraphTitle && (
        <meta name='twitter:title' content={metadata.twitterOpenGraphTitle} />
      )}
      {metadata?.twitterOpenGraphDescription && (
        <meta
          name='twitter:description'
          content={metadata.twitterOpenGraphDescription}
        />
      )}

      {metadata?.facebookImagePath && (
        <meta
          name='facebook:image'
          content={mediaBaseUrl + metadata.facebookImagePath}
        />
      )}
      {metadata?.facebookOpenGraphTitle && (
        <meta name='facebook:title' content={metadata.facebookOpenGraphTitle} />
      )}
      {metadata?.facebookOpenGraphDescription && (
        <meta
          name='facebook:description'
          content={metadata.facebookOpenGraphDescription}
        />
      )}

      {metadata?.linkedinImagePath && (
        <meta
          name='linkedin:image'
          content={mediaBaseUrl + metadata.linkedinImagePath}
        />
      )}
      {metadata?.linkedinOpenGraphTitle && (
        <meta name='linkedin:title' content={metadata.linkedinOpenGraphTitle} />
      )}
      {metadata?.linkedinOpenGraphDescription && (
        <meta
          name='linkedin:description'
          content={metadata.linkedinOpenGraphDescription}
        />
      )}

      {metadata?.pinterestImagePath && (
        <meta
          name='pinterest:image'
          content={mediaBaseUrl + metadata.pinterestImagePath}
        />
      )}
      {metadata?.pinterestOpenGraphTitle && (
        <meta
          name='pinterest:title'
          content={metadata.pinterestOpenGraphTitle}
        />
      )}
      {metadata?.pinterestOpenGraphDescription && (
        <meta
          name='pinterest:description'
          content={metadata.pinterestOpenGraphDescription}
        />
      )}
    </Head>
  );
};

export default SeoHead;
