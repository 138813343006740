import ConfirmModal from '@appComponents/modals/paymentSuccessfullModal/paymentSuccessfullModal';
import SeoHead from '@appComponents/reUsable/SeoHead';
import {
  __Cookie,
  __Error,
  __pageTypeConstant,
} from '@constants/global.constant';
import { paths } from '@constants/paths.constant';
import HomeController from '@controllers/homeControoler';
import { newFetauredItemResponse } from '@definations/productList.type';
import { _GetPageType } from '@definations/slug.type';
import {
  GTMHomeScriptForAllStores,
  GTMHomeScriptForCG,
  extractCookies,
} from '@helpers/common.helper';
import { highLightError } from '@helpers/console.helper';
import { useActions_v2, useTypedSelector_v2 } from '@hooks_v2/index';
import { FetchDataByBrand } from '@services/brand.service';
import { getPageComponents, getFeaturedProducts } from '@services/home.service';
import { FetchPageType } from '@services/slug.service';
import Home from '@templates/Home';
import { _SelectedTab } from '@templates/ProductDetails/productDetailsTypes/storeDetails.res';
import {
  GetServerSideProps,
  GetServerSidePropsResult,
  GetStaticProps,
  GetStaticPropsResult,
  NextPage,
} from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { _globalStore } from 'store.global';
import {
  getHomeCompFromAPI,
  getHomePageComponents,
  getHomePageMetaData,
  getStaticStoreDetails,
} from 'staticData/staticFile.helper';
import { customerJourney } from '@services/app.service';

export interface _Slug_CMS_Props {
  page: 'ALL_CMS_PAGES';
  data: {
    components: any;
    featuredItems: { [x: string]: newFetauredItemResponse[] } | [];
  };
  metaData: _GetPageType;
  html: any;
}

type _HomeProps =
  | _Slug_CMS_Props
  | {
      error: __Error.noPageTypeFound | __Error.storeIdMissing;
    };

const DefaultHomePage: NextPage<_HomeProps> = (props) => {
  const { id: customerId } = useTypedSelector_v2((state) => state.user);
  const { id: storeId } = useTypedSelector_v2((state) => state.store);
  const isCaptured = useRef(false);
  const { hideModal } = useActions_v2();
  const [modalopen, setmodalopen] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    hideModal();
  }, []);

  useEffect(() => {
    if (storeId && !isCaptured.current) {
      isCaptured.current = true;
      GTMHomeScriptForAllStores('HomePage', storeId, customerId || 0);
      GTMHomeScriptForCG('HomePage', storeId, customerId || 0);
    }
  }, [storeId, customerId]);

  useEffect(() => {
    if ('data' in props) {
      let featuredProduct =
        props &&
        props.data?.components.find(
          (comp: any) => comp.html.includes('Featured_Products') == true,
        );
      if (featuredProduct) {
        localStorage.setItem(
          'Featured Products',
          JSON.stringify(featuredProduct?.selectedVal),
        );
      }
    }
  }, [props]);

  useEffect(() => {
    if (
      localStorage.getItem('OrderNumber') &&
      router?.query &&
      router?.query?.OrderNumber === localStorage.getItem('OrderNumber')
    ) {
      setmodalopen(true);
      localStorage.removeItem('OrderNumber');
    }
  }, [router]);

  const userId = extractCookies(__Cookie.userId, 'browserCookie').userId;

  useEffect(() => {
    const payload = {
      customerId: userId || 0,
      pageName: 'Homepage',
      pageTitle: router.pathname,
    };
    customerJourney(payload);
  }, []);

  if ('error' in props) {
    return <>{props.error}</>;
  }

  const { metaData, data, html } = props;

  return (
    <>
      <SeoHead
        title={metaData?.meta_Title ? metaData?.meta_Title : 'Home'}
        description={
          metaData?.meta_Description ? metaData?.meta_Description : ''
        }
        keywords={
          metaData?.meta_Keywords
            ? metaData?.meta_Keywords
            : 'Branded Promotional'
        }
        metadata={metaData}
      />

      <Home html={html} pageData={data} />

      {modalopen && <ConfirmModal setmodalopen={setmodalopen} />}
    </>
  );
};

export default React.memo(DefaultHomePage);

export const getStaticProps: GetStaticProps = async (): Promise<
  GetStaticPropsResult<_HomeProps>
> => {
  const storeId = (await getStaticStoreDetails()).id || 7;

  try {
    const pageMetaData: _GetPageType | null = await FetchPageType({
      storeId: storeId,
      slug: '',
    });

    if (!pageMetaData) throw new Error('Page Meta Data API failed');

    const { components, featureProducts, homeComponents }: any =
      await getHomeCompFromAPI(pageMetaData.id, storeId);

    return {
      props: {
        page: 'ALL_CMS_PAGES',
        data: {
          components: components,
          featuredItems: [featureProducts] as any,
        },
        metaData: pageMetaData,
        html: homeComponents,
      },
      revalidate: 3600,
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};
